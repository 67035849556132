import React from "react";
import Notification from "../../controllers/notification";
import SchoolWillOrderNotification from "../../controllers/school-will-order-notification";

export default class BookListSubmitButton extends React.Component {
  constructor(props) {
    super(props);

    this.dataButtonClass = document
      .getElementById(props.baseElement)
      .getAttribute("data-buttoncss");
    this.onClick = this._clickHandler.bind(this);
  }

  _clickHandler() {
    const selectedItems = this.props.selectedItems;
    const booklistActive = selectedItems[6] ? selectedItems[6].Active : true;
      if (booklistActive) {
          document.location = this.props.goto;
      }
      else {
        const booklistId = selectedItems[6] ? selectedItems[6].Id : -1;
        const booklistName = selectedItems[6] ? selectedItems[6].Name : "";
        const schoolName = selectedItems[0] ? selectedItems[0].Name : "";

        if (selectedItems[6].ShowPrivateOrderingPopup) {            
            SchoolWillOrderNotification.show();        }
        else {
            Notification.show(schoolName, booklistId, booklistName);
        }
    }
  }

  render() {
    const buttonClasses = this.props.loading
      ? `loading ${this.dataButtonClass}`
      : this.dataButtonClass;
    return (
      <button
        className={buttonClasses}
        id="bl-sel-submit"
        disabled={!this.props.enabled}
        onClick={this.onClick}
        data-toggle="tooltip"
        data-placement="top"
      >
        {Resources.BooklistSelector.BooklistSubmitButton}
      </button>
    );
  }
}
