import React from 'react';

export default class BooklistCategory extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        this.props.onCategoryChange(this.props.organizationFilterId);
    }

    render() {
        const identifier = `booklist__selector_category_checkbox_${this.props.organizationFilterId}`;

        return (
          <div className={this.props.isChecked ? 'col-3 booklist__selector_category active' : 'col-3 booklist__selector_category'}>
            <input id={identifier} type="checkbox" onChange={this.onChange} checked={this.props.isChecked} />
            <label htmlFor={identifier}>{this.props.name}</label>
          </div>
        );
    }
}
