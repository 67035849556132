export default class SchoolWillOrderNotification {
    constructor() {
    }

    static show() {
        const modal = $('#schoolorder-notification-modal');
        console.log("show modal: ", modal);
        modal.modal('show');
    }
}
