import React from 'react';

export default class BooklistSelectorAutosuggestClearButton extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    getClassName() {
        if (this.props.disabled) {
            return 'bl-btn icon-cross';
        } else if (this.props.isActive) {
            return 'bl-btn icon-arrow-down';
        } else {
            return 'bl-btn icon-arrow-down rotated';
        }
    }

    onClick(e) {
        if (this.props.disabled) {
            this.props.onClear(this.props.index, e);
        } else {
            this.props.onActivationToggle(this.props.index, e);
        }
    }

    render() {
        return (
            <button
                id={`button-${this.props.index}`}
                className={this.getClassName()}
                onClick={this.onClick}
            />
        );
    }
}