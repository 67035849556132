export default class Notification {
    constructor(addNotificationUrl) {
        if (typeof addNotificationUrl === 'undefined') {
            console.warn('WARNING! Parameters missing - Could not initialize Notification.'); // eslint-disable-line no-console
            return;
        }

        this.addNotificationUrl = addNotificationUrl;
    }

    static hideMessages() {
        $('#notification-sent').hide();
        $('#notification-error').hide();
        $('#notification-sending').hide();
    }

    /* eslint-disable consistent-return */
    addNotification() {
        // check if the form is not valid or there are pending remote validation requests (for email)
        // If so, cancel this function so jQuery validator can show the validation results
        if ($('#notification-form').valid() !== true || $('#notification-form').validate().pendingRequest > 0) {
            return false;
        }

        //Check if there is a '.com' or '.nl' in the email
        if (/\.\w{2,10}/.test($('#notification-email').val()) === false) {
            return false;
        }

        const model = {
            EmailAddress: $('#notification-email').val(),
            BooklistId: $('#booklistId').val(),
            BooklistName: $('#booklistName').val(),
        };

        // track school notification
        if ($('#schoolName').val() !== '' && (window.ga && ga.create)) {
            ga('send', 'event', 'Booklistnotification', $('#schoolName').val());
        }

        $('#notification-form-input').hide();
        $('#add-notification').hide();
        $('#notification-sending').show();

        $.post(this.addNotificationUrl, model)
            .done((result) => {
                if (result === 'True') {
                    Notification.hideMessages();
                    $('#notification-sent').show();
                } else {
                    Notification.hideMessages();
                    $('#notification-error').show();
                }
            });
    }
    /* eslint-enable func-names */

    static show(schoolName, booklistId, name) {
        Notification.hideMessages();

        $('#notification-form-input').show();
        $('#add-notification').show();

        $('#booklistId').val(booklistId);
        $('#booklistNameDisplay').html(name);
        $('#schoolName').val(schoolName);
        $('#booklistName').val(name);

        $('#notification-modal').modal('show');
    }
}
