import React from 'react';

export default class BooklistSelectorHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    renderHeading() {
        if (typeof Resources.BooklistSelector.Title !== 'undefined' && Resources.BooklistSelector.Title !== "") {
            if (typeof Resources.BooklistSelector.TitleHowDoIOrderLink !== 'undefined' && Resources.BooklistSelector.TitleHowDoIOrderLink !== "") {
                return (
                    <h1 className={"heading__with-icon"}>
                        {Resources.BooklistSelector.Title}
                    </h1>
                );
            }
            return (
                <h1>{Resources.BooklistSelector.Title}</h1>
            );
        }
        return "";
    }

    render() {
        const heading = this.renderHeading();

        if (heading !== "") {
            return (
                <div className={this.props.isLandingPage ? "bookListSelector__header bookListSelector__header_landingpage" : "bookListSelector__header"}>
                    {heading}
                </div>
            );
        }
        else {
            return (
                <div className={"bookListSelector__header_emptyHeader"}></div>
            );
        }

        return (null);
    }
}
