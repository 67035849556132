import React from "react";
import BooklistSubmitButton from "./booklistselectorsubmitbutton";

export default class BooklistSelectorFooter extends React.Component {
  constructor(props) {
    super(props);
  }

    renderLinks() {
      const booklistImportEnabled = this.props.booklistImport.enable;
      return (
          <div className="col-auto order-2 order-sm-1">
              { booklistImportEnabled
                ? <a className="bookListSelector-alternative" href="#">{this.props.booklistImport.text}</a>
                : null
              }              
          </div>
      );
  }

  renderFooter() {
   const homepage = document.getElementById("homepage");
    return (
      <div className="row">
        { homepage
            ? this.renderLinks()
            : null
        }
        <div className="col-12 col-sm-auto align-self-end ml-auto order-1 order-sm-2">
          <BooklistSubmitButton
            baseElement={this.props.baseElement}
            enabled={this.props.state.enableButton}
            loading={this.props.state.loading}
            goto={this.props.state.booklistUrl}
            selectedItems={this.props.state.selectedItems}
          />
        </div>
      </div>
    );
  }

  render() {
    const footer = this.renderFooter();

    return <div className="bookListSelector__footer">{footer}</div>;
  }
}
