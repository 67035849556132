import React from 'react';
import BooklistCategory from './category';

export default class BooklistCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCategory: -1,
        };

        this.onCategoryChange = this.onCategoryChange.bind(this);
    }

    onCategoryChange(organizationFilterId) {
        organizationFilterId = this.state.selectedCategory === organizationFilterId ? -1 : organizationFilterId;
        this.setState({ selectedCategory: organizationFilterId });
        this.props.onCategoryChange(organizationFilterId);
    }

    render() {
        return (
            <div className="container booklist__selector_categories d-none d-md-block">
                <div className="row">
                    <BooklistCategory name={Resources.BooklistSelector.College} organizationFilterId={0} isChecked={this.state.selectedCategory === 0} onCategoryChange={this.onCategoryChange} />
                    <BooklistCategory name={Resources.BooklistSelector.University} organizationFilterId={1} isChecked={this.state.selectedCategory === 1} onCategoryChange={this.onCategoryChange} />
                    <BooklistCategory name={Resources.BooklistSelector.StudentUnion} organizationFilterId={2} isChecked={this.state.selectedCategory === 2} onCategoryChange={this.onCategoryChange} />
                    <BooklistCategory name={Resources.BooklistSelector.Other} organizationFilterId={3} isChecked={this.state.selectedCategory === 3} onCategoryChange={this.onCategoryChange} />
                    <span className="booklist_selector_arrow-up"></span>
                </div>
            </div>
        );
    }
}
