import React from 'react';

export default class BooklistSelectorAutosuggestHeader extends React.Component {
    render() {
        if (this.props.name !== '' && typeof this.props.name !== 'undefined' && this.props.name !== null) {
            return (
                <span className="bookListSelector__autosuggest-header">{this.props.name}</span>
            );
        }
        return (null);
    }
}